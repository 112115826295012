
import { update } from "plotly.js";
import React, { Component, useEffect, useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import { Link, useParams, Router } from 'react-router-dom';
import Header from '../../component/Header';
import backIcon from "../../images/back.png";
import { API_URL } from "../../../config";
import md5 from "md5";

const Subscriptionrecharge = () => {

    const { t } = useTranslation();
    // const { userid } = useParams();
    const accessToken = localStorage.getItem('accessToken');
    const userid = localStorage.getItem('user_id');
    const [owner, setOwner] = useState([]);
    const [autoupdate, setAutoUpdate] = useState(0);
    const [autorenew, setAutoRenew] = useState(0);
    const [deviceLength, setDeviceLength] = useState(0);
    // const [deviceLength, setDeviceLength] = useState(0);
    const [ownerfive, setOwnerfive] = useState([]);
    const [ownersix, setOwnersix] = useState([]);
    const [ownerseven, setOwnerseven] = useState([]);
    const [selectedValueradiobtn, setSelectedValueradiobtn] = React.useState("0.01");
    const [balance, setBalance] = useState([]);


    const [messageModal, setmessageModal] = useState(false);
    const messageToggle = () => setmessageModal(!messageModal);

    const [message, setmessage] = useState(false);
    const [messageHead, setmessageHead] = useState(false);

    const [perDevice, setPerDevice] = useState(175);
    const [perGoDevice, setPerGoDevice] = useState(75);
    const [deviceToRecharge, setDevcieToRecharge] = useState([]);
    const [payPalModal, setPayPalModal] = useState(false);
    const payPalToggle = () => setPayPalModal(!payPalModal);

    useEffect(() => {
        getOwnerProfile();
        getassociatedserialkey();
       
    }, [])

    useEffect(() => {

        if (deviceToRecharge.length > 0 && owner.user_type) {
            payNow(deviceToRecharge.length, selectedValueradiobtn)

        }
    }, [deviceToRecharge, owner, perDevice,selectedValueradiobtn])

    const handleCheckbox = (e) => {
        let _devices = deviceToRecharge.slice();

        if (e.target.checked) {
            _devices.push(e.target.value)
        }
        else if (!e.target.checked) {
            _devices.splice(_devices.indexOf(e.target.value), 1);

        }
        // alert(_devices.length)
        setDevcieToRecharge(_devices);
    }


    const getassociatedserialkey = () => {
        fetch(API_URL + "/get/device/by/owner/" + md5(userid.toString()),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("result", resp);
                    setOwnerfive(resp.data.five.list)
                    let _devices = deviceToRecharge.slice();

                    resp.data.five.list.map((v, i) => {
                        if (new Date().getTime() / 1e3 > v.expires_at) {
                            _devices.push(v.serial_key);
                        }
                    })
                    setOwnersix(resp.data.six.list)
                    resp.data.six.list.map((v, i) => {
                        if (new Date().getTime() / 1e3 > v.expires_at) {
                            _devices.push(v.serial_key);
                        }
                    })
                    setOwnerseven(resp.data.seven.list)
                    resp.data.seven.list.map((v, i) => {
                        if (new Date().getTime() / 1e3 > v.expires_at) {
                            _devices.push(v.serial_key);
                        }
                    })
                    // setDevcieToRecharge(_devices)

                    setDeviceLength(resp.data.total);
                    // let _deviceLength = resp.data.total;
                    // setPerDevice(_deviceLength == 1 ? 175 : _deviceLength == 2 ? 150 : _deviceLength == 3 ? 125 : _deviceLength >= 4 ? 100 : 175)

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }


    // const getbalance = () => {
    //     fetch(API_URL + "/get/transaction/details/by/" + md5(userid.toString()),
    //         {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-access-token': accessToken,
    //             },
    //         }
    //     ).then((response) => {
    //         if (response.status == 200) {
    //             response.json().then((resp) => {
    //                 // console.log("result", resp);
    //                 setBalance(resp.data)
    //             });
    //         }
    //         else if (response.status == 401) {
    //             logout()
    //         }
    //         else {
    //             console.log("network error")
    //         }


    //     })
    // }

    const getOwnerProfile = () => {


        fetch(API_URL + "/user/profile/" + userid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {

                    setOwner(resp.owner[0]);



                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }



    const payNow = (_deviceLength, selectedValueradiobtn) => {
        $("#paypal-button-container").html('')
        // _perPrice = 0.01
        let _price = _deviceLength * selectedValueradiobtn;
        _price = parseFloat(_price);
        // _price = 0.01;
        if (_price > 0) {
            paypal.Buttons({
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: "USD",
                                value: _price,
                                breakdown: {
                                    item_total: {  /* Required when including the `items` array */
                                        currency_code: "USD",
                                        value: _price
                                    }
                                }
                            },
                            items: [
                                {
                                    name: "CapnoTrainer Device Recharge", /* Shows within upper-right dropdown during payment approval */
                                    description: "Annual Recharge of CapnoTrainer Devices for " + owner.firstname + owner.lastname + ". Device(s) Serial:" + deviceToRecharge.join(', '), /* Item details will also be in the completed paypal.com transaction view */
                                    unit_amount: {
                                        currency_code: "USD",
                                        value: selectedValueradiobtn
                                    },
                                    quantity: _deviceLength
                                },
                            ]
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        //   // console.log(details)

                        completeRecharge(deviceToRecharge, details);

                    })
                },
                onCancel: function (data) {
                    // window.location.replace("<?php echo $site_url;?>sales/CeRegister_new_back.php")
                }
            }).render('#paypal-button-container');
        }


    }


    const completeRecharge = async (id, details) => {


        const response = await fetch(API_URL + '/save/transaction/details/' + userid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
                recharge_day: selectedValueradiobtn == "0.01"?"7":"30",
                details: JSON.stringify(details)
            }),
        })

        const data = await response.json()

        if (data.success) {
            setmessageHead("Thank you!")
            setmessage("Your payment for reacharge was successful. <br /> <br /> You will now be redirected to your cloud dashboard.");
            messageToggle();

            setTimeout(() => {
                window.location.replace('https://www.capnolearning.com')
            }, 10000)

        }
        else {
            setmessageHead("Error!!")
            setmessage("Some Error Occured")
            messageToggle();


        }
    }


    

    return (
        <div>
            <Header />
            <div className="wrp-dashbord pt-5">
                {/* <div className="sidebar-section"> */}
                {/* <Sidebar /> */}
                {/* </div> */}
                <div className="container-fluid" >

                    <div className="row mt-5">
                        <div className="col-lg-3 mt-5">
                        </div>
                        <div className="col-lg-6">
                            <div className="back-renew">
                                <Link to={owner.user_type == 5 ? "/go/personal/subscription/management" : owner.user_type == 6 ? "/go/basic/subscription/management" : "/subscription/management"} className="backbtn-icon">
                                    <img src={backIcon} alt="backicon" />
                                    <span>Back</span>
                                </Link>
                            </div>
                            <div className="subscription-content">

                                <div className="notification-c">
                                    <h5 className="text-center">Tethering Balance</h5>
                                    <p><b>{"Account Name"}</b>: {owner.firstname} {owner.lastname}.</p>
                                    <p><b>{"Device(s) Registered"}</b>: {deviceLength}</p>
                                    <p><b>{t('Membership-Status')}</b> {owner.status == 1 ? "Active" : "Inactive"}
                                        {/* (<b>{t('Expiry-Date')}</b>: {owner.expire_account ? new Date(owner.expire_account * 1e3).toDateString() : "NA"}) */}
                                    </p>
                                </div>
                                <div className="notification-c">
                                    <p><b>{"Choose devices to recharge"} <i className="fa fa-arrow-down"></i> </b></p>
                                </div>
                                <ul className="nostyle">
                                    {/* {
                                        ownerfive.length > 0 && ownerfive.map((val, i) => {
                                            return (
                                                <li><input type="checkbox" onChange={(e) => handleCheckbox(e)} value={val.serial_key} /> Serial number <b>{val.serial_key}: Balance ($ {balance[i]?.amount?balance[i]?.amount: "0"})</b></li>
                                            )
                                        })
                                    }

                                    {
                                        ownersix.length > 0 && ownersix.map((val, i) => {
                                            return (
                                                <li><input type="checkbox" onChange={(e) => handleCheckbox(e)} value={val.serial_key} /> Serial number <b>{val.serial_key}: Balance ($ {balance[i]?.amount?balance[i]?.amount: "0"})</b></li>
                                            )
                                        })
                                    } */}
                                    {
                                        ownerseven.length > 0 && ownerseven.map((val, i) => {
                                            return (
                                                <li><input type="checkbox" onChange={(e) => handleCheckbox(e)} value={val.serial_key}  /> Serial number <b>{val.serial_key}: Linking balance ({val?.linking_balance} days) </b></li>
                                            )
                                        })
                                    }
                                </ul>
                                {/* <ul className="anual-renew-list">
                                    <li><h3 className="mb-0">{t('Annual renewal fee per device')}</h3></li>
                                    <li><p>{owner.user_type == 5 || owner.user_type == 6 ?  "$75" : deviceLength == 1 ? "$175" : deviceLength == 2 ? "$150"  : deviceLength == 3 ? "$125" : deviceLength >= 4 ? "$100" :  "No Device Found"}</p></li>
                                </ul> */}

                                {
                                    deviceToRecharge.length > 0 &&
                                    <>
                                        <div className="checkboxrechargewrp">
                                            <div className="rechergecheckbox">
                                                <p>
                                                    <input
                                                        type="radio"
                                                        value="0.01"
                                                        className="renewCheckbox"
                                                        name="recharge"
                                                        checked={selectedValueradiobtn === "0.01"}
                                                        onChange={(e) => setSelectedValueradiobtn(e.target.value)}
                                                    />
                                                    <span>7 days</span>
                                                </p>
                                            </div>
                                            <div className="rechergecheckbox">
                                                <p>
                                                    <input
                                                        type="radio"
                                                        value="0.02"
                                                        className="renewCheckbox"
                                                        name="recharge"
                                                        checked={selectedValueradiobtn === "0.02"}
                                                        onChange={(e) => setSelectedValueradiobtn(e.target.value)}
                                                    />
                                                    <span>30 days</span>
                                                </p>
                                            </div>
                                        </div>

                                        <h3 className="mb-0">{t('Total Cost')}: ${parseFloat(deviceToRecharge.length * selectedValueradiobtn)} </h3>

                                        <div id="paypal-button-container" className="mt-4"></div>
                                    </>
                                }
                                {/* <button onClick={() => completeRecharge(userid,"ttryt")} >NI</button> */}


                            </div>

                        </div>
                        <div className="col-lg-3 mt-5">
                        </div>

                    </div>

                </div>
            </div>


            <Modal isOpen={messageModal} toggle={messageToggle} className="connect-box" centered={true}>
                <ModalHeader toggle={messageToggle}><span className="ml-1 roititle font-weight-bold">{messageHead}</span></ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{ __html: message }}>

                    </div>
                </ModalBody>

            </Modal>

        </div>
    )
}

export default Subscriptionrecharge;