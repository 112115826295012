import React, { Component, useEffect, useRef, useState } from "react";
import { Link, useParams, Router,useLocation } from 'react-router-dom';
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import { useTranslation, initReactI18next } from "react-i18next";
import downloads from 'downloadjs';
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import Sidebar from '../../component/Sidebar';
import Header from '../../component/Header';
import MaterialTable from 'material-table';
import download from '../../images/download.png'
import preveiw from '../../images/preveiw.png'
import Delete from '../../images/delete.png';
import closeicon from '../../images/closeicon.png';
import { API_URL, FORM_URL, SERVER_URL } from "../../../config";
import backIcon from "../../images/back.png";
import checks from '../../images/checks.png'
import Cross from '../../images/cross.png';



const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "black",
        fontSize: "15px"
    }
}));

const ManageSession = () => {
    const { t } = useTranslation();
    const accessToken = localStorage.getItem('accessToken');
    const selectedClient = localStorage.getItem('selectedClient');
    const userId = localStorage.getItem('user_id');
    const [selectedclientval, setSelectedclientval] = useState(localStorage.getItem('selectedClient') == "null"?userId :localStorage.getItem('selectedClient'))
    const [data, setdata] = useState([]);
  
    const [itemId, setItemId] = useState(null);
    const [clients, setinclients] = useState([]);
    const [trainers, settrainers] = useState([]);
    const M_module = localStorage.getItem('m_module');
    const [message, setMessage] = useState('');

    const trainerActive = useRef()

    const clientSelected = useRef()
    const trainerInactive = useRef()
    const clientActive = useRef()
    const clientInactive = useRef()
    const trainerSelected = useRef()
    const [successModal, setsuccessModal] = useState(false);
    const successToggleModal = () => setsuccessModal(!successModal);

    const [loaderModal, setLoaderModal] = useState(false);
    const loaderToggleModal = () => setLoaderModal(!loaderModal);

    const groupSelected = useRef()
    const cid = useRef()
    const sessionSelected = useRef()
    
    const selectedTrainer = localStorage.getItem('selectedTrainer');
    const selectedGroup = localStorage.getItem('selectedGroup');
    const selectedtrainerActive = localStorage.getItem('selectedtrainerActive');
    const selectedtrainerInactive = localStorage.getItem('selectedtrainerInactive');
    const selectedclientActive = localStorage.getItem('selectedclientActive');
    const selectedclientInactive = localStorage.getItem('selectedclientInactive');
    const selectedHomework = localStorage.getItem('selectedHomework');
    const userType = localStorage.getItem('userType');
    const selectedSession = localStorage.getItem('selectedSession');
    const location = useLocation();

    const backlocation = location?.state?.managesessionid;

    const [deleteModal, setdeleteModal] = useState(false);
    const deleteToggleModal = () => setdeleteModal(!deleteModal);
    const [status, setStatus] = useState(0);
    const [statusModal, setStatusModal] = useState(false);
    const statusToggleModal = () => setStatusModal(!statusModal);




    const classes = useStyles();

    useEffect(() => {

        if(userType == 3 || userType == 4 || userType == 5){
            setinclients([])
            settrainers([])
           }else if(userType == 2 || userType == 6 || (userType == 5 && M_module == 1)){
            getClients()
            settrainers([])
           }else{
    
            getTrainers();
            getClients();
    
           }
           setInterval(() => {

            setSelectedclientval(localStorage.getItem('selectedClient') == "null"?userId :localStorage.getItem('selectedClient'));

        }, 1000);
    

        // if (selectedClient != "null") {
        //     getclientSession();
        // }

        getclientSession();

    }, [selectedClient]);

  
    const getTrainers = () => {
        
        let url = API_URL + "/trainers?user_id=" + userId + "&status=2";
        let _trainerActive = trainerActive.current.checked;
        let _trainerInactive = trainerInactive.current.checked;

        if (_trainerActive && !_trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId + "&status=1";;
        }
        else if (!_trainerActive && _trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId + "&status=0";
        }
        else if (_trainerActive && _trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId;
        }
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }

        ).then((response) => {
            if (response.status == 200) {
                response.json().then((result) => {
                    if (result.status) {
                        settrainers(result.trainers)
                        // getClients();
                    }

                    else {
                        alert("no data error")
                    }

                })
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        }).catch(err => {
            // // console.log(err)

        })
    }
    const getClients = () => {
   

        let _userId = localStorage.getItem('selectedTrainer');
       
        let _selectedGroup = localStorage.getItem('selectedGroup');
        let _userType = 3
        let _groupSelected = _selectedGroup === "true" ? true : false;
        let _trainer = true;
        if (_userId == "all") {
            _trainer = false;
            _userId = localStorage.getItem('user_id');
        }

        localStorage.setItem('selectedGroup', false);
        if (_groupSelected) {
            localStorage.setItem('selectedGroup', true);
            _userType = 4;
        }

        if (clientActive.current.checked) {
            localStorage.setItem('selectedclientActive', true);
        }
        else {
            localStorage.setItem('selectedclientActive', false);

        }
        if (clientInactive.current.checked) {
            localStorage.setItem('selectedclientInactive', true);
        }
        else {
            localStorage.setItem('selectedclientInactive', false);

        }
        let url = API_URL + "/clients?user_id=" + _userId + "&status=2&trainer=" + _trainer + "&user_type=" + _userType;
        let _clientActive = clientActive.current.checked;
        let _clientInactive = clientInactive.current.checked;


        if (_clientActive && !_clientInactive) {
            url = API_URL + "/clients?user_id=" + _userId + "&status=1&trainer=" + _trainer + "&user_type=" + _userType;
        }
        else if (!_clientActive && _clientInactive) {
            url = API_URL + "/clients?user_id=" + _userId + "&status=0&trainer=" + _trainer + "&user_type=" + _userType;
        }
        else if (_clientActive && _clientInactive) {
            url = API_URL + "/clients?user_id=" + _userId + "&trainer=" + _trainer + "&user_type=" + _userType;
        }


        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }

        ).then((response) => {
            if (response.status == 200) {
                response.json().then((result) => {
                    if (result.status) {
                        setinclients(result.clients)
                    }

                    else {
                        alert("no data error")
                    }

                })
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        }).catch(err => {
            // // console.log(err)

        })
    }

   
    const openStatusPopUp = (id,status) => {
        setItemId(id);
        setStatus(status)
        setStatusModal(true)

    }

    const getclientSession = ()=>{

        fetch(API_URL + "/get/client/sessions/by/" + selectedclientval,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                }
            ).then((response) => {
                if (response.status == 200) {
                    response.json().then((resp) => {
                        let _temp = [];
            
                        resp.data.map((v, i) => {
            
                                _temp.push({
                                    name: v.name,
                                    Status: v.status == 1? "Activate": "Deactivate",
                                    Status: v.status == 1? "Activate": "Deactivate", 
                                    created: new Date(v.created).toLocaleDateString(),
                                    action: <p><Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        
                                      }} title={(v.status == 1 ? "Deactivate" : "Activate")}  placement="top"><a onClick={() => openStatusPopUp(v.id,v.status)} className="downloadimg"><img src={(v.status == 1 ? Cross : checks)} /></a></Tooltip>    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
    
                                    }} title="Delete" placement="top"><a onClick={() => openItemPopUp(v.id)} className="downloadimg tooltip2"><img src={Delete} /></a></Tooltip></p>
                                   
    
                                })  
                        })
    
                        setdata(_temp);
    
                    });
                }else if (response.status == 404) {
                    setdata([])
                }
                else if (response.status == 401) {
                    logout()
                }
                else {
                    console.log("network error")
                }
            })
    }


    const updateSelectClient = () => {
        localStorage.setItem('selectedClient', clientSelected.current.value);
        let clientid = clientSelected?.current.value
            fetch(API_URL + "/get/client/sessions/by/" + clientid,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                }
            ).then((response) => {
                if (response.status == 200) {
                    response.json().then((resp) => {
                        let _temp = [];
            
                        resp.data.map((v, i) => {
            
                                _temp.push({
                                    name: v.name,
                                    Status: v.status == 1? "Activate": "Deactivate", 
                                    created: new Date(v.created).toLocaleDateString(),
                                    action: <p><Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        
                                      }} title={(v.status == 1 ? "Deactivate" : "Activate")}  placement="top"><a onClick={() => openStatusPopUp(v.id,v.status)} className="downloadimg"><img src={(v.status == 1 ? Cross : checks)} /></a>
                                      </Tooltip>   <Tooltip classes={{
                                        tooltip: classes.customTooltip,
    
                                    }} title="Delete" placement="top"><a onClick={() => openItemPopUp(v.id)} className="downloadimg tooltip2"><img src={Delete} /></a></Tooltip></p>
                                    
    
                                })  
                        })
    
                        setdata(_temp);
    
                    });
                }
                else if (response.status == 404) {
                    setdata([])
                }
                else if (response.status == 401) {
                    logout()
                }
                else {
                    console.log("network error")
                }
    
            })
        

    }

    const updateStatussession = ()=>{
            let data = {};
    
            data['status'] = status == 1?0: 1;
    
            fetch(API_URL + "/update/session/status/by/" + itemId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.status == 200) {
                    response.json().then((resp) => {
                        successToggleModal();
                        getclientSession();
                        setMessage("The session status has been successfully " + (status == 1 ? "deactivated" : "activated") + ".")
                        statusToggleModal();
    
                    });
                }
                
                else if (response.status == 401) {
                    logout()
                }
                else {
                    console.log("network error")
                }
            })  
    }

    const updateSelectTrainer = () => {
        localStorage.setItem('selectedTrainer', trainerSelected.current.value);
        localStorage.setItem('selectedClient', null);


        getClients()
        setdata([])
    }

    // const updatedselectformname = () =>{
    //     localStorage.setItem('selectedformname', formname.current.value);
    // }


    const deleteSession = () => {
        let id = itemId;
        setLoaderModal(true)

        fetch(API_URL + "/delete/session/data/by/" + id,
            {
                method: 'Delete',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                getclientSession();
                setdeleteModal(!deleteModal)
                setLoaderModal(false)
                setMessage('Session has been deleted successfully.')
                setsuccessModal(true)

            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }
        })

    }
    const openItemPopUp = (id) => {
       
        setItemId(id);
        setdeleteModal(true)
    }
    const columns = [
        {
            title: "Session Name", field: "name"
        },
        {
            title: "Status", field: "Status"
        },
        {
            title: "Created Date", field: "created"
        },
        {
            title: <span >Actions</span>, field: "action", align: "right"
        }
    ]


    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <div className="">
            <Header />
            <div className="wrp-dashbord">
                <div className="sidebar-section">
                    <Sidebar />
                </div>
                <div className="right-section">
                    <div className="wrp-view-uploadclientform">
               
                        <div className="back-icon-wrp">
                            {/* <Link to={userType == 6 || userType ==5?"/go/dashboard": "/dashboard"} className="backbtn-icon">
                                <img src={backIcon} alt="backicon" />
                                <span>Back</span>
                            </Link> */}

                            <Link to={userType == 6 || userType ==5?"/go/dashboard": backlocation == 1?"/dashboard": backlocation == 2?"/view/data/report": backlocation == 3?"/view/pdf/report": backlocation == 4?"/view/live": backlocation == 5?"/view/manageform":backlocation == 6?"/case/report/assembly":"/dashboard"} className="backbtn-icon">
                                <img src={backIcon} alt="backicon" />
                                <span>Back</span>
                            </Link>
                        </div>
                        <div className="uploadclientformheading"><h3>Manage Sessions</h3></div>
                    </div>
                    <div className="filter-top">
                        <div className="row">
                            {
                                userType == 3 || userType == 4 || (userType == 5 && M_module == 0) ? "" : <>

                                {
                                    (userType == 2 || userType == 6 || (userType == 5 && M_module == 1))?"": <div className="col-lg-4">
                                    <div className="trainerbox">
                                        <div className="trainer-c"><p>{t("trainer")}:</p></div>
                                        <div className="padding-box">
                                            <div className="main-checkbox">

                                                <div className="checkbox-wrp">
                                                    <div class="custom-radios">
                                                        <input type="checkbox" id="6" onChange={getTrainers} ref={trainerActive} defaultChecked={(selectedtrainerActive === "true" ? true : false)} />
                                                        <label for="6">
                                                            <span className="redious">
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="caption-cheeckbox">
                                                        <p>{t("Active")}</p>
                                                    </div>
                                                </div>
                                                <div className="checkbox-wrp">
                                                    <div class="custom-radios">
                                                        <input type="checkbox" id="7" onChange={getTrainers} ref={trainerInactive} defaultChecked={(selectedtrainerInactive === "true" ? true : false)} />
                                                        <label for="7">
                                                            <span className="redious">
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="caption-cheeckbox">
                                                        <p>{t("Inactive")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="select-client">
                                                <select ref={trainerSelected} onChange={updateSelectTrainer} required>
                                                    <option className="selected-bold">Choose a trainer</option>
                                                    <option className="selected-bold" value={"all"}>All trainers</option>
                                                    {
                                                        trainers.map((items) =>
                                                            <option className="selected-bold" selected={items.id == selectedTrainer ? true : false} value={items.id}>
                                                                {items.firstname} {items.lastname}
                                                            </option>)
                                                    }



                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                   
                                    <div className="col-lg-4">
                                        <div className="trainerbox">
                                            <div className="trainer-c"><p>{t("Client")}:</p></div>
                                            <div className="padding-box">
                                                <div className="main-checkbox">

                                                    <div className="checkbox-wrp">
                                                        <div class="custom-radios">
                                                            <input type="checkbox" id="color-8" onChange={getClients} ref={clientActive} defaultChecked={(selectedclientActive === "true" ? true : false)} />
                                                            <label for="color-8">
                                                                <span className="redious">
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="caption-cheeckbox">
                                                            <p>{t("Active")}</p>
                                                        </div>
                                                    </div>

                                                    <div className="checkbox-wrp">
                                                        <div class="custom-radios">
                                                            <input type="checkbox" id="color-10" onChange={getClients} ref={clientInactive} defaultChecked={(selectedclientInactive === "true" ? true : false)} />
                                                            <label for="color-10">
                                                                <span className="redious">
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="caption-cheeckbox">
                                                            <p>{t("Inactive")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="select-client">
                                                    <select ref={clientSelected} onChange={updateSelectClient}>
                                                        <option className="selected-bold" value="null">Choose a client</option>

                                                        {
                                                            clients.length > 0 && clients.map((client, i) =>
                                                                <option className="selected-bold" selected={client.id == selectedClient ? true : false} value={client.id}>
                                                                    {client.firstname} {client.lastname}
                                                                </option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                           



                        </div>
                    </div>


                    <div className="wrp-bankform">
                        <div className="table-box">
                            <MaterialTable
                                columns={columns}
                                data={data}
                                title=""
                                options={{
                                    pageSize: 15,

                                    pageSizeOptions: [5, 10, 15, 20]
                                }}
                            // options={{
                            //     fixedColumns: {

                            //       right: 1
                            //     }
                            //   }}
                            />

                        </div>
                    </div>

                    <Modal isOpen={deleteModal} toggle={deleteToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={deleteToggleModal}><span className="ml-1 roititle font-weight-bold">Delete</span></ModalHeader>
                        <ModalBody>
                            <div className="modal-p">
                                <div className="right-circle cancel-circle"><img src={closeicon} /></div>
                                <h4>Are you sure?</h4>
                                <p>Do you really wish to delete this session?</p>
                                <div className="wrp-delete-btn">
                                    <div className="cancel-btn1" ><a onClick={deleteToggleModal}>Cancel</a></div>
                                    <div className="delete-btn1"><a onClick={deleteSession}>Delete</a></div>
                                </div>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={loaderModal} toggle={loaderToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={loaderToggleModal}><span className="ml-1 roititle modal-head">Request processing...</span></ModalHeader>
                        <ModalBody>
                            <p className='text-center'>Please wait...</p>
                            <div className="wrp-chart-loader">
                                <div class="loading">
                                    <div class="loading-1"></div>
                                    <div class="loading-2"></div>
                                    <div class="loading-3"></div>
                                    <div class="loading-4"></div>
                                </div>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={successModal} toggle={successToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={successToggleModal}><span className="ml-1 roititle font-weight-bold">Successfull</span></ModalHeader>
                        <ModalBody>
                            <div className="modal-p">
                                <p>{message}</p>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={statusModal} toggle={statusToggleModal} className="connect-box" centered={true}>
                    <ModalHeader toggle={statusToggleModal}><span className="ml-1 roititle font-weight-bold">{(status == 1 ? "Deactivate" : "Activate")}</span></ModalHeader>
                    <ModalBody>
                        <div className="modal-p">
                            {/* <div className="right-circle cancel-circle"><img src={(status == 1 ? Cross : checks)} /></div> */}
                            <h4>Are you sure?</h4>
                            <p>Do you really wish to {(status == 1 ? "deactivate" : "activate")} this session?</p>
                            <div className="wrp-delete-btn">
                                <div className="cancel-btn1" ><a onClick={statusToggleModal}>Cancel</a></div>
                                <div className="delete-btn1"><a onClick={updateStatussession}>{(status == 1 ? "Deactivate" : "Activate")}</a></div>
                            </div>
                        </div>
                    </ModalBody>

                </Modal>
                </div>
            </div>

        </div>
    )
}

export default ManageSession;