import React, { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import $ from "jquery";
import Footer from '../../pages/footer.js';




class Home extends Component{ 
	constructor(props) {
	  super(props);
	  this.state = {
	  };
  
	}
	
	componentDidMount = () =>{

		
				
	  }

	render(){
		return(
			<div>
				home
			</div>
		);
	}

}
export default Home;