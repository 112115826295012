
import { update } from "plotly.js";
import React, { Component, useEffect, useRef, useState } from "react";
import { ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import { Link } from 'react-router-dom'

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import Header from '../../component/Header';
import Sidebar from '../../component/Sidebar';
import { API_URL } from "../../../config";
import backIcon from "../../images/back.png"

const Subscriptionmanagement = () => {

    const { t } = useTranslation();
    const userType = localStorage.getItem('userType');
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('user_id');
    // const [owner, setOwner] = useState([]);
    const [autoupdate, setAutoUpdate] = useState(0);
    const [autorenew, setAutoRenew] = useState(0);
    const [successModal, setsuccessModal] = useState(false);
    const successToggleModal = () => setsuccessModal(!successModal);
    const [owner, setOwner] = useState([]);
    const [ownerfive, setOwnerfive] = useState([]);
    const [ownersix, setOwnersix] = useState([]);
    const [ownerseven, setOwnerseven] = useState([]);


    const associatedOwner = localStorage.getItem('associated_owner');
    const dateval = new Date();


    useEffect(() => {
        getOwnerProfile();
        getassociatedserialkey();
    }, [])

    const getOwnerProfile = () => {
        fetch(API_URL + "/owner/profile/" + userId,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("result", resp);
                    setOwner(resp.owner[0]);



                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }


    const getassociatedserialkey = () => {
        fetch(API_URL + "/get/device/by/owner/" + associatedOwner,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("result", resp);
                    setOwnerfive(resp.data.five.list)
                    setOwnersix(resp.data.six.list)
                    setOwnerseven(resp.data.seven.list)

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }
    const subscriptionSave = (_autorenew, _autoupdate) => {
        let data = {};

        data['autoupdate'] = _autoupdate;
        data['autorenew'] = _autorenew;


        fetch(API_URL + "/owner/subscription/update/" + userId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("results", resp);
                    successToggleModal();

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }
            // alert("Updated")

        })

    }
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    const handleAutoUpdate = (v) => {
        setAutoUpdate(v);

        subscriptionSave(autorenew, v);


    }

    const handleAutorenew = (v) => {
        setAutoRenew(v);

        subscriptionSave(v, autoupdate);


    }


    return (
        <div>
            <Header />

            <div className="wrp-dashbord">
                <div className="sidebar-section">
                    <Sidebar />
                </div>
                <div className="right-section">
                    <div className="back-managementbtn">

                    </div>
                    <div className="subscription-content">
                        <h3>SUBSCRIPTION MANAGEMENT</h3>
                        {/* <h3>GO PROFESSIONAL SUBSCRIPTION MANAGEMENT:  {owner.expire_account ? new Date(owner.expire_account*1e3).toDateString() : "NA"}</h3> */}
                        <div className="software-updated-wrp">
                            <div className="software-updt-child1">
                                <h3>Auto update computer software:</h3>

                            </div>
                            <div className="software-updt-child1">
                                {
                                    owner.autoupdate >= 0 ?
                                        <div className="checkbox-wrp">

                                            <div className="radio-input"><input class="form-check-input" onChange={() => handleAutoUpdate(0)} type="radio" defaultChecked={owner.autoupdate == 0 ? true : false} value="0" name="autoaupdate" /><span>No</span></div>
                                            <div className="radio-input"> <input class="form-check-input" name="autoaupdate" type="radio" defaultChecked={owner.autoupdate == 1 ? true : false} value="1" onChange={() => handleAutoUpdate(1)} /><span>Yes</span></div>



                                        </div>
                                        :
                                        null
                                }


                            </div>
                        </div>
                        <div className="software-updated-wrp">
                        <div className="software-updt-child1">
                                    <h3>Auto subscription renewal with credit card:</h3>
                                </div>
                                <div className="software-updt-child1">
                                    {
                                        owner.autorenew >= 0 ?
                                            <div className="checkbox-wrp">
                                                <div className="radio-input"> <input class="form-check-input" name="autorenew" type="radio" defaultChecked={owner.autorenew == 1 ? true : false} value="1" onChange={() => handleAutorenew(1)} /><span>Yes</span></div>
                                                <div className="radio-input"><input class="form-check-input" onChange={() => handleAutorenew(0)} type="radio" defaultChecked={owner.autorenew == 0 ? true : false} value="0" name="autorenew" /><span>No</span></div>

 
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                </div>
                        <div className="renew-wrp">
 
                            <div className="paragraphgo">
                                <p>If yes, click here to enter <b><u><a href={"/subscription/renew/"}>credit card information</a></u></b>.</p>
                                <p>Auto-charging is on the due date.</p>
                            </div>
                            <div className="receive-paragraph">
                                <p>You will receive a renewal subscription notice 30 days and 10 days in advance of the due date.</p>
                                <p>Your renewal dates are as follows for each of your instruments.</p>
                                <br></br>
                                <ul className="ownerlistserialkey">
                                {
                                        ownerfive.length > 0 && ownerfive.map((val, i) => {
                                            return (
                                                <li>Serial number <b>{val.serial_key}: <span className={new Date().getTime()/1e3 > val.expires_at ? "red" :""}>{parseInt(new Date().getTime()/1e3) > val.expires_at ? "Expired on" :"Expires on"} {val.expires_at ? new Date(val.expires_at * 1e3).toLocaleDateString('en-IN', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    year: 'numeric'

                                                }) : "NA"}</span></b></li>
                                            )
                                        })
                                    }

                                    {
                                        ownersix.length > 0 && ownersix.map((val, i) => {
                                            return (
                                                <li>Serial number <b>{val.serial_key}: <span className={new Date().getTime()/1e3 > val.expires_at ? "red" :""}>{parseInt(new Date().getTime()/1e3) > val.expires_at ? "Expired on" :"Expires on"}  {val.expires_at ? new Date(val.expires_at * 1e3).toLocaleDateString('en-IN', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    year: 'numeric',

                                                }) : "NA"}</span></b></li>
                                            )
                                        })
                                    }
                                    {
                                        ownerseven.length > 0 && ownerseven.map((val, i) => {
                                            return (
                                                <li>Serial number <b>{val.serial_key}: <span className={new Date().getTime()/1e3 > val.expires_at ? "red" :""}>{parseInt(new Date().getTime()/1e3) > val.expires_at ? "Expired on" :"Expires on"}  {val.expires_at ? new Date(val.expires_at * 1e3).toLocaleDateString('en-IN', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    year: 'numeric'

                                                }) : "NA"}</span></b></li>
                                            )
                                        })
                                    }
                                </ul>


                            </div>
                        </div>


                        <div className="notification-c">

                            <h3>Annual SUBSCRIPTION RATES <span>for Professional system instruments:</span></h3>
                            <div className="notify-step-p">
                                <p>1 instrument: $175.00</p>
                                <p>2 instruments: $150 each</p>
                                <p>3 instruments: $125.00 each</p>
                                <p>4 or more instruments: $100.00 each</p>

                            </div>
                            {/* <div className="mebership-status">
                           <p><b>{t('Membership-Status')}</b> Active (<b>{t('Expiry-Date')}</b>: March 7,2022).</p>
                           </div> */}
                        </div>

                        <p className="renewparagraph">You may <b><u><Link to={"/subscription/renew/"}>renew your subscription</Link></u></b> at any time by clicking here.</p>
                        
                        {
                            userType == 5?<p className="renewparagraph">You may <b><u><Link to={"/subscription/recharge"}>recharge your subscription</Link></u></b> at any time by clicking here.</p>: ""
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={successModal} toggle={successToggleModal} className="connect-box" centered={true}>
                <ModalHeader toggle={successToggleModal}><span className="ml-1 roititle font-weight-bold">Successfull</span></ModalHeader>
                <ModalBody>
                    <div className="modal-p">

                        <p>Updated Successfully</p>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    )
}

export default Subscriptionmanagement;