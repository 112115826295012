import React, {useEffect,useState} from "react";
import {Link,useParams, Router} from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import Header from '../../component/Header';
import Filter from '../../component/Filter';
import Sidebar from '../../component/Sidebar';
import { API_URL } from "../../../config";
import Header2 from "../../component/Header2";


const Viewpdfreport = () =>{

    const { t } = useTranslation();
    const accessToken = localStorage.getItem('accessToken');
    const sessionid = localStorage.getItem('selectedSession');
    const [pdfs, setpdfs] = useState([]);
    const [multiplepdfs, setmultiplePdf] = useState([]);
    const [sessionNotes, setSessionNotes] = useState([]);
    const [homeworkdata,setHomeworkdata] = useState([])
    const [grouplist,setGrouplist] = useState([])
    const Clientid = localStorage.getItem('selectedClient');
    const [selectedClient,setSelectedClient] = useState() ;
    const [selectedSession,setSelectedSession] = useState() ;
    const [selectedGroup,setselectedGroup] = useState() ;
    const [selectedHomework,setselectedHomework] = useState();
    const [selectedStandard,setselectedStandard] = useState();
    const [selectedclientactive, setSelectedclientactive] = useState();
    const [userType,setUserType] = useState() ;
  

    

    // useEffect(() => {
    //     setUserType(localStorage.getItem('userType'));

    //     setInterval(() => {
    //         setSelectedClient(localStorage.getItem('selectedClient'));
    //         setSelectedSession(localStorage.getItem('selectedSession'));
    //         setselectedGroup(localStorage.getItem('selectedGroup'));
    //         setselectedHomework(localStorage.getItem('selectedHomework'));
    //         setselectedStandard(localStorage.getItem('selectedStandard'));
    //     //    // console.log(selectedSession);
    //     setUserType(localStorage.getItem('userType'));

    //     }, 100);

    // }, []);

    useEffect(() => {
        // Load initial values from localStorage on component mount
        setUserType(localStorage.getItem('userType'));
        setSelectedClient(localStorage.getItem('selectedClient'));
        setSelectedSession(localStorage.getItem('selectedSession'));
        setselectedGroup(localStorage.getItem('selectedGroup'));
        setselectedHomework(localStorage.getItem('selectedHomework'));
        setselectedStandard(localStorage.getItem('selectedStandard'));
    
        // Set up interval to update state from localStorage
        const interval = setInterval(() => {
          setUserType(localStorage.getItem('userType'));
          setSelectedClient(localStorage.getItem('selectedClient'));
          setSelectedSession(localStorage.getItem('selectedSession'));
          setselectedGroup(localStorage.getItem('selectedGroup'));
          setselectedHomework(localStorage.getItem('selectedHomework'));
          setselectedStandard(localStorage.getItem('selectedStandard'));
          setSelectedclientactive(localStorage.getItem('selectedclientActive'));
        }, 100);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
      }, []);
    

    useEffect(() => {
        Singlesession();
        Multisession();
        pdfReportNote()
        homeworkdatalist()
        groupdatalist()
    },[selectedSession,selectedClient,selectedGroup,selectedHomework,selectedStandard])
      
    const Singlesession = () => {
        fetch(API_URL+"/report/single/pdf?session_id=" + sessionid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    setpdfs(resp.pdfs);
                   
                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }
    const Multisession = () => {
        fetch(API_URL+"/report/multiple/pdf?client_id=" + Clientid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    setmultiplePdf(resp.pdfs);
                   

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }

    const pdfReportNote = () => {
        fetch(API_URL+"/report/notes?session_id=" + sessionid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    console.warn("result", resp);
                   
                    setSessionNotes(resp.notes)

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }

    const homeworkdatalist = () => {


        fetch(API_URL + "/report/single/pdf?session_id=" + sessionid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                   setHomeworkdata(resp.pdfs);

                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }


    const groupdatalist = () => {


        fetch(API_URL + "/report/single/pdf?session_id=" + sessionid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    setGrouplist(resp.pdfs)


                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }
   
    const logout = () => {
        localStorage.clear();
        // alert("You Logout successful")
    }


    return(
        <div>
        <Header />
        
        <div className="wrp-dashbord">
            <div className="sidebar-section">
             <Sidebar />
            </div>
            <div className={(userType == 5 || userType == 6)?"right-section2":"right-section"}>
            <Filter managesessionid="3" />
            <div className="create-section">
                       <ul className="create-list">

                        {
                            userType == 3?<> <li>
                            
                            <div className="create-list-box"><Link to={(pdfs.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "" || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/single" } className={(pdfs.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }> {t('pdf-Session-Data-Reports')}</Link></div>
                        </li>
                       
                        <li>
                            <div className="create-list-box"><Link to={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/sessetion/report/notes" } className={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false" ) ? "deactivate": "" }>{t('Session-Report-Notes')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(homeworkdata.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedHomework === "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/homework" } className={(homeworkdata.length == 0 ||selectedSession === "null" || selectedSession === "" || selectedHomework === "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Client-Homework-Data-Session')}</Link></div>
                        </li>
                        </>:userType == 2?<> <li>
                            
                            <div className="create-list-box"><Link to={(pdfs.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "" || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/single" } className={(pdfs.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }> {t('pdf-Session-Data-Reports')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(multiplepdfs.length == 0 || selectedClient === "" || selectedGroup === "true" || (selectedSession !== "" && selectedSession !== "null") || selectedclientactive == "false") ? "": "/pdf/session/data/report/multi" } className={(multiplepdfs.length == 0 || selectedClient === "" || selectedGroup === "true" || (selectedSession !== "" && selectedSession !== "null") || selectedclientactive == "false") ? "deactivate": "" }>{t('pdf-Multi-session-Data-Reports')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(homeworkdata.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedHomework === "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/homework" } className={(homeworkdata.length == 0 ||selectedSession === "null" || selectedSession === "" || selectedHomework === "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Client-Homework-Data-Session')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/sessetion/report/notes" } className={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Session-Report-Notes')}</Link></div>
                        </li></>:userType == 4?<>
                        <li>
                            <div className="create-list-box"><Link to={(grouplist.length == 0 || selectedSession === "" ||  selectedSession === "" ||selectedSession === "null" || selectedHomework === "true" || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/group" } className={(grouplist.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedSession === "" || selectedHomework === "true" || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }> {t('pdf-Group-Session-Data-Reports')} </Link></div>
                        </li>
                        {/* <li>
                            <div className="create-list-box"><Link to={(homeworkdata.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedHomework === "false") ? "": "/pdf/session/data/report/homework" } className={(homeworkdata.length == 0 ||selectedSession === "null" || selectedSession === "" || selectedHomework === "false") ? "deactivate": "" }>{t('Client-Homework-Data-Session')}</Link></div>
                        </li> */}
                        <li>
                            <div className="create-list-box"><Link to={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/sessetion/report/notes" } className={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Session-Report-Notes')}</Link></div>
                        </li></>:<> <li>
                            
                            <div className="create-list-box"><Link to={(pdfs.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "" || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/single" } className={(pdfs.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }> {t('pdf-Session-Data-Reports')}</Link></div>
                        </li>
                        <li>
                            {/* {selectedSession} */}
                            <div className="create-list-box"><Link to={(multiplepdfs.length == 0 || selectedClient === "" || selectedGroup === "true" || (selectedSession !== "" && selectedSession !== "null") || selectedclientactive == "false") ? "": "/pdf/session/data/report/multi" } className={(multiplepdfs.length == 0 || selectedClient === "" || selectedGroup === "true" || (selectedSession !== "" && selectedSession !== "null") || selectedclientactive == "false") ? "deactivate": "" }>{t('pdf-Multi-session-Data-Reports')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(grouplist.length == 0 || selectedSession === "" || selectedGroup === "false" || selectedSession === "" ||selectedSession === "null" || selectedHomework === "true" || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/group" } className={(grouplist.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedGroup === "false" || selectedSession === "" || selectedHomework === "true" || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }> {t('pdf-Group-Session-Data-Reports')} </Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(homeworkdata.length == 0 || selectedSession === "" ||selectedSession === "null" || selectedHomework === "false" || selectedclientactive == "false") ? "": "/pdf/session/data/report/homework" } className={(homeworkdata.length == 0 ||selectedSession === "null" || selectedSession === "" || selectedHomework === "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Client-Homework-Data-Session')}</Link></div>
                        </li>
                        <li>
                            <div className="create-list-box"><Link to={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "": "/pdf/sessetion/report/notes" } className={(sessionNotes.length == 0 || (selectedSession === "" ||selectedSession === "null" || selectedGroup === "true" || selectedHomework === "true" || selectedClient === "") || selectedStandard == "false" || selectedclientactive == "false") ? "deactivate": "" }>{t('Session-Report-Notes')}</Link></div>
                        </li></>
                        }
                       
                       </ul>
                   </div>
              
            </div>
        </div>
     </div>

    )
}

export default Viewpdfreport;