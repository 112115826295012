import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import MaterialTable from 'material-table';
import { DateRangePicker, Radio } from 'rsuite';
import { API_URL } from "../../../config";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import backIcon from "../../images/back.png";
import checks from '../../images/checks.png'
import Delete from '../../images/delete.png';
import Cross from '../../images/cross.png';
import closeicon from '../../images/closeicon.png';
import Header from "../../component/Header";




const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "black",
        fontSize: "15px"
    }
}));

const GoManageSession = () => {


    const { t } = useTranslation();
    const classes = useStyles();
    const [clients, setinclients] = useState([]);
    const [trainers, settrainers] = useState([]);
    const [session, setsession] = useState([]);
    const [Idupdate, setIdupdate] = useState(null)
    const trainerActive = useRef()
    const trainerInactive = useRef()
    const clientActive = useRef()
    const clientInactive = useRef()
    const trainerSelected = useRef()
    const groupSelected = useRef()
    const clientSelected = useRef()
    const sessionSelected = useRef()

    const userId = localStorage.getItem('user_id');
    const selectedTrainer = localStorage.getItem('selectedTrainer');
    const selectedClient = localStorage.getItem('selectedClient');
    const selectedSession = localStorage.getItem('selectedSession');
    const selectedGroup = localStorage.getItem('selectedGroup');
    const [selectedclientval, setSelectedclientval] = useState(localStorage.getItem('selectedClient'))

    const selectedtrainerActive = localStorage.getItem('selectedtrainerActive');
    const selectedtrainerInactive = localStorage.getItem('selectedtrainerInactive');
    const selectedclientActive = localStorage.getItem('selectedclientActive');
    const selectedclientInactive = localStorage.getItem('selectedclientInactive');
    const [data, setdata] = useState([]);
    const [message, setMessage] = useState('');

    const [itemId, setItemId] = useState(null);

    const accessToken = localStorage.getItem('accessToken');

    const _cid = localStorage.getItem('selectedClient');

    const userType = localStorage.getItem('userType');
    const M_module = localStorage.getItem('m_module');


    const [deleteModal, setdeleteModal] = useState(false);
    const deleteToggleModal = () => setdeleteModal(!deleteModal);
    const [status, setStatus] = useState(0);
    const [statusModal, setStatusModal] = useState(false);
    const statusToggleModal = () => setStatusModal(!statusModal);

    const [successModal, setsuccessModal] = useState(false);
    const successToggleModal = () => setsuccessModal(!successModal);

    const [loaderModal, setLoaderModal] = useState(false);
    const loaderToggleModal = () => setLoaderModal(!loaderModal);



    useEffect(() => {
        if (userId && (userType == 1 || userType == 7)) {
            getTrainers();
        }
        if (selectedTrainer && (userType == 1 || userType == 2 || (M_module == 1 && userType == 5) || userType == 6 || userType == 7)) {
            getClients();
        }

        // getSession();


        setInterval(() => {

            setSelectedclientval(localStorage.getItem('selectedClient'));


        }, 1000);

        if (selectedClient != "null") {
            getclientSession();
        }


    }, [selectedClient])



    const getTrainers = () => {
        setinclients([])

        let url = API_URL + "/trainers?user_id=" + userId + "&status=2";
        // // console.log(trainerActive);
        let _trainerActive = trainerActive.current.checked;
        let _trainerInactive = trainerInactive.current.checked;

        if (trainerActive.current.checked) {
            localStorage.setItem('selectedtrainerActive', true);
        }
        else {
            localStorage.setItem('selectedtrainerActive', false);

        }
        if (trainerInactive.current.checked) {
            localStorage.setItem('selectedtrainerInactive', true);
        }
        else {
            localStorage.setItem('selectedtrainerInactive', false);

        }

        if (_trainerActive && !_trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId + "&status=1";;
        }
        else if (!_trainerActive && _trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId + "&status=0";
        }
        else if (_trainerActive && _trainerInactive) {
            url = API_URL + "/trainers?user_id=" + userId;
        }
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }

        ).then((response) => {
            if (response.status == 200) {
                response.json().then((result) => {
                    // console.log(result.trainers)
                    if (result.status) {
                        settrainers(result.trainers)
                        // getClients();
                    }

                    else {
                        alert("no data error")
                    }

                })
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        }).catch(err => {
            // // console.log(err)

        })
    }


    const getClients = () => {
        const _cid = localStorage.getItem('user_id');
        setsession([]);



        let url = API_URL + "/get/go/client/" + _cid;

        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }

        ).then((response) => {
            if (response.status == 200) {
                response.json().then((result) => {
                    // console.log(result.clients)

                    setinclients(result.clients)

                })
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        }).catch(err => {
            // // console.log(err)

        })


    }

    const updateStatussession = () => {


        let data = {};

        data['status'] = status == 1 ? 0 : 1;


        fetch(API_URL + "/update/session/status/by/" + itemId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("results", resp);
                    successToggleModal();
                    getclientSession();
                    setMessage("The session status has been successfully " + (status == 1 ? "deactivated" : "activated") + ".")
                    statusToggleModal();

                });
            }

            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })


    }

    const openStatusPopUp = (id, status) => {
        setItemId(id);
        setStatus(status)
        setStatusModal(true)

    }

    const getclientSession = () => {
        fetch(API_URL + "/get/client/sessions/by/" + selectedclientval,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    let _temp = [];

                    resp.data.map((v, i) => {

                        _temp.push({
                            name: v.name,
                            Status: v.status == 1 ? "Activate" : "Deactivate",
                            created: new Date(v.created).toLocaleDateString(),
                            action: <p><Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title={(v.status == 1 ? "Deactivate" : "Activate")} placement="top"><a onClick={() => openStatusPopUp(v.id, v.status)} className="downloadimg"><img src={(v.status == 1 ? Cross : checks)} /></a></Tooltip>    <Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title="Delete" placement="top"><a onClick={() => openItemPopUp(v.id)} className="downloadimg tooltip2"><img src={Delete} /></a></Tooltip></p>


                        })


                    })

                    setdata(_temp);

                });
            } else if (response.status == 404) {
                setdata([])
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }


    const deleteSession = () => {
        let id = itemId;
        setLoaderModal(true)

        fetch(API_URL + "/delete/session/data/by/" + id,
            {
                method: 'Delete',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                getclientSession();
                setdeleteModal(!deleteModal)
                setLoaderModal(false)
                setMessage('Session has been deleted successfully.')
                setsuccessModal(true)

            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })

    }
    const openItemPopUp = (id) => {

        setItemId(id);
        setdeleteModal(true)
    }
    const columns = [
        {
            title: "Session Name", field: "name"
        },
        {
            title: "Created Date", field: "created"
        },
        {
            title: "Status", field: "Status"
        },
        {
            title: <span >Actions</span>, field: "action", align: "right"
        }
    ]




    //    trainer check react
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }
    const updateSelectClient = () => {
        localStorage.setItem('selectedClient', clientSelected.current.value);

        let clientid = clientSelected?.current.value



        fetch(API_URL + "/get/client/sessions/by/" + clientid,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    let _temp = [];

                    resp.data.map((v, i) => {

                        _temp.push({
                            name: v.name,
                            created: new Date(v.created).toLocaleDateString(),
                            action: <p><Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title={(v.status == 1 ? "Deactivate" : "Activate")} placement="top"><a onClick={() => openStatusPopUp(v.id, v.status)} className="downloadimg"><img src={(v.status == 1 ? Cross : checks)} /></a></Tooltip> </p>
                            //   <Tooltip classes={{
                            //     tooltip: classes.customTooltip,

                            // }} title="Delete" placement="top"><a onClick={() => openItemPopUp(v.id)} className="downloadimg tooltip2"><img src={Delete} /></a></Tooltip>

                        })


                    })

                    setdata(_temp);

                });
            }
            else if (response.status == 404) {
                setdata([])
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })


    }
    const updateSelectTrainer = () => {
        localStorage.setItem('selectedTrainer', trainerSelected.current.value);
        localStorage.setItem('selectedClient', null);

        getClients()
    }
    const updateselectedSecssion = () => {

        localStorage.setItem('selectedSession', sessionSelected.current.value);


    }




    return (
        <div>
            <Header />

            <div className="gowrpmanagesession">
                <div className="backwrpicon">
                <div className="back-icon-wrp">
                    <Link to="/go/dashboard" className="backbtn-icon">
                        <img src={backIcon} alt="backicon" />
                        <span>Back</span>
                    </Link>
                </div>
                </div>
               
                <div className="wrp-step-box wrp-step-box6 wrp-step-box7">


                    {
                        userType == 1 || userType == 7 ? <> <div className="step-box">
                            <div className="step-trainers-box">
                                <p>{t('Trainer-box')}</p>
                            </div>
                            <div className="main-checkbox">

                                <div className="checkbox-wrp">
                                    <div class="custom-radios">
                                        <input type="checkbox" id="active" onChange={getTrainers} ref={trainerActive} defaultChecked={(selectedtrainerActive === "true" ? true : false)}
                                        />
                                        <label for="active">
                                            <span className="redious">
                                            </span>
                                            <b className="lactive">{t('Active')}</b>
                                        </label>
                                    </div>


                                </div>

                                <div className="checkbox-wrp">
                                    <div class="custom-radios">
                                        <input type="checkbox" id="inactive" onChange={getTrainers} ref={trainerInactive} defaultChecked={(selectedtrainerInactive === "true" ? true : false)} />
                                        <label for="inactive">
                                            <span className="redious">
                                            </span>
                                            <b className="lactive">{t('Inactive')}</b>
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="select-client">
                                <select ref={trainerSelected} onChange={updateSelectTrainer}  >
                                    <option className="selected-bold">{t('Choose-a-trainer')}</option>
                                    <option value={"all"} className="selected-bold">All trainers</option>
                                    {
                                        trainers.map((items) =>
                                            <option className="selected-bold" selected={items.id == selectedTrainer ? true : false} value={items.id}>
                                                {items.firstname} {items.lastname}
                                            </option>)
                                    }

                                </select>
                            </div>

                        </div></> : ""


                    }

                    {
                        (userType == 2 || userType == 1 || userType == 7 || userType == 6 || (M_module == 1 && userType == 5)) ?

                            <div className="step-box">
                                <div className="step-trainers-box">
                                    <p>{t("CLIENTS")}</p>
                                </div>
                                <div className="main-checkbox">

                                    <div className="checkbox-wrp">

                                        {
                                            ((M_module == 1 && userType == 5) || userType == 6) ?
                                                "" : <>

                                                    <div class="custom-radios">
                                                        <input type="checkbox" id="color-9" value="color-9" onChange={getClients} ref={clientActive} defaultChecked={(selectedclientActive === "true" ? true : false)} />
                                                        <label for="color-9">
                                                            <span className="redious">
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="caption-cheeckbox">
                                                        <p>Active</p>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                    <div className="checkbox-wrp">
                                        {
                                            (userType == 6 || (M_module == 1 && userType == 5)) ? "" : <><div class="custom-radios">
                                                <input type="checkbox" id="color-10" value="color-10" onChange={getClients} ref={clientInactive} defaultChecked={(selectedclientInactive === "true" ? true : false)} />
                                                <label for="color-10">
                                                    <span className="redious">
                                                    </span>
                                                </label>
                                            </div>
                                                <div className="caption-cheeckbox">
                                                    <p>Inactive</p>
                                                </div></>
                                        }

                                    </div>
                                    <div className="checkbox-wrp">
                                        {
                                            (userType == 6 || (M_module == 1 && userType == 5)) ? "" : <><div class="custom-radios">
                                                <input type="checkbox" id="color-8" value="color-8" onChange={handleGroup} ref={groupSelected} defaultChecked={(selectedGroup === "true" ? true : false)} />
                                                <label for="color-8">
                                                    <span className="redious">
                                                    </span>
                                                </label>
                                            </div>
                                                <div className="caption-cheeckbox">
                                                    <p>{t('Group')}</p>
                                                </div></>
                                        }

                                    </div>
                                </div>
                                <div className={((M_module == 1 && userType == 5) || userType == 6) ? `smallboxgodevice select-client` : "select-client"}>
                                    <select ref={clientSelected} onChange={updateSelectClient}>

                                        {
                                            selectedGroup == "true" ? <option className="selected-bold">Choose a Group</option> : <option className="selected-bold">{t('Choose-a-client')}</option>
                                        }


                                        {
                                            clients.length > 0 && clients.map((client, i) =>
                                                <option className="selected-bold" selected={client.id == selectedClient ? true : false} value={client.id}>
                                                    {client.firstname} {client.lastname}
                                                </option>)
                                        }
                                    </select>
                                </div>

                            </div> : ""

                    }
                </div>



                <div className="right-section3 paddingmobile2">

                    <div className="wrp-bankform">
                        <div className="table-box">
                            <MaterialTable
                                columns={columns}
                                data={data}
                                title=""
                                options={{
                                    pageSize: 10,

                                    pageSizeOptions: [5, 10, 15, 20]
                                }}
                            // options={{
                            //     fixedColumns: {

                            //       right: 1
                            //     }
                            //   }}
                            />

                        </div>
                    </div>


                    <Modal isOpen={deleteModal} toggle={deleteToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={deleteToggleModal}><span className="ml-1 roititle font-weight-bold">Delete</span></ModalHeader>
                        <ModalBody>
                            <div className="modal-p">
                                <div className="right-circle cancel-circle"><img src={closeicon} /></div>
                                <h4>Are you sure?</h4>
                                <p>Do you really wish to delete this session?</p>
                                <div className="wrp-delete-btn">
                                    <div className="cancel-btn1" ><a onClick={deleteToggleModal}>Cancel</a></div>
                                    <div className="delete-btn1"><a onClick={deleteSession}>Delete</a></div>
                                </div>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={loaderModal} toggle={loaderToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={loaderToggleModal}><span className="ml-1 roititle modal-head">Request processing...</span></ModalHeader>
                        <ModalBody>
                            <p className='text-center'>Please wait...</p>
                            <div className="wrp-chart-loader">
                                <div class="loading">
                                    <div class="loading-1"></div>
                                    <div class="loading-2"></div>
                                    <div class="loading-3"></div>
                                    <div class="loading-4"></div>
                                </div>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={successModal} toggle={successToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={successToggleModal}><span className="ml-1 roititle font-weight-bold">Successfull</span></ModalHeader>
                        <ModalBody>
                            <div className="modal-p">
                                <p>{message}</p>
                            </div>
                        </ModalBody>

                    </Modal>


                    <Modal isOpen={statusModal} toggle={statusToggleModal} className="connect-box" centered={true}>
                        <ModalHeader toggle={statusToggleModal}><span className="ml-1 roititle font-weight-bold">{(status == 1 ? "Deactivate" : "Activate")}</span></ModalHeader>
                        <ModalBody>
                            <div className="modal-p">
                                {/* <div className="right-circle cancel-circle"><img src={(status == 1 ? Cross : checks)} /></div> */}
                                <h4>Are you sure?</h4>
                                <p>Do you really wish to {(status == 1 ? "deactivate" : "activate")} this session?</p>
                                <div className="wrp-delete-btn">
                                    <div className="cancel-btn1" ><a onClick={statusToggleModal}>Cancel</a></div>
                                    <div className="delete-btn1"><a onClick={updateStatussession}>{(status == 1 ? "Deactivate" : "Activate")}</a></div>
                                </div>
                            </div>
                        </ModalBody>

                    </Modal>
                </div>



            </div>
        </div>
    );
}

export default GoManageSession;