import { removeData } from 'jquery';
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import i18n from "i18next";
import { API_URL } from '../../../config';
import { useTranslation, initReactI18next } from "react-i18next";
import user from '../../images/user.png'

const LoginMultiSession = () => {
    const { t } = useTranslation();

    const { access, report  } = useParams()
    const navigate = useNavigate();

    async function loginUser() {



        const response = await fetch(API_URL + '/setlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                access
            }),
        })

        const data = await response.json()
        
        
        if (data.status) {
            localStorage.clear();
            localStorage.setItem('user_id', data.user_id);
            localStorage.setItem('associated_practioner', data.associated_practioner);
            localStorage.setItem('associated_owner', data.associated_owner);
            localStorage.setItem('session_id', data.user_id);
            localStorage.setItem('client_id', data.user_id);
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('selectedGroup', false);
            localStorage.setItem('selectedtrainerActive', true);
            localStorage.setItem('selectedStandard', true);
            localStorage.setItem('selectedtrainerInactive', false);
            localStorage.setItem('selectedclientActive', true);
            localStorage.setItem('selectedclientInactive', false);
            localStorage.setItem('selectedHomework', false);
            localStorage.setItem('selectedTrainer', data.user_id);
            localStorage.setItem('selectedClient', null); 
            localStorage.setItem('selectedTrainerGroup', null); 
            localStorage.setItem('selectLanguage', null);
            // localStorage.setItem('selectedGroup', false);
            localStorage.setItem('userType', data.user_type);
         
            navigate("/view/multi/report/1/0/" + report)            
      
          
 

        } else {
            alert("Invalid Path/Login")


        }

        // alert('Logined')
    }
    useEffect(() => {
        if (access != "") {
            loginUser()
            // alert("id")

            // localStorage.setItem('selectedTrainer', trainer);
            // localStorage.setItem('selectedClient', client);
            // localStorage.setItem('selectedSession', session);
            // navigate(`/create/report/0/12/${session}/all/12`);

        }
    }, access)
    return (
        <>
        </>
    );
}


export default LoginMultiSession;