import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import Header from '../../component/Header';
import MaterialTable from 'material-table';
import edit from '../../images/edit.png'
import checks from '../../images/checks.png'
import Delete from '../../images/delete.png';
import closeicon from '../../images/closeicon.png';
import { API_URL } from '../../../config';
import backIcon from "../../images/back.png";
import crosss from '../../images/crosss.png';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';



import Cross from '../../images/cross.png';

const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "black",
        fontSize: "15px"
    }
}));

const EditGoclient = () => {

    const accessToken = localStorage.getItem('accessToken');
    const selectedTrainer = localStorage.getItem('selectedTrainer');
    const selectedGroup = localStorage.getItem('selectedGroup');
    const userId = localStorage.getItem('user_id');
    const [clients, setinclients] = useState([]);
    const [data, setData] = useState([]);
    const [clientName, setClientName] = useState();

    const [trainers, settrainers] = useState([]);
    const trainerSelected = useRef();
    const [itemId, setItemId] = useState(null);
    const [deleteModal, setdeleteModal] = useState(false);
    const deleteToggleModal = () => setdeleteModal(!deleteModal);
    let _userId = localStorage.getItem('user_id');
    let _userType = 3
    let _trainer = false;


    const [status, setStatus] = useState(0);
    const [statusModal, setStatusModal] = useState(false);
    const statusToggleModal = () => setStatusModal(!statusModal);



    const [statssuccessModal, setstatssuccessModal] = useState(false);
    const statussuccessToggleModal = () => setstatssuccessModal(!statssuccessModal);

    const [successModal, setsuccessModal] = useState(false);
    const successToggleModal = () => setsuccessModal(!successModal);

    const [dataid, setdataid] = useState(7);

    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    const classes = useStyles();

    useEffect(() => {
        getClients();
        getTrainer();

        // const interval = setInterval(() => {
        //     getClients();
        // }, 3000);
        // return () => clearInterval(interval);

    }, []);

    const deleteClient = () => {
        let id = itemId;
        fetch(API_URL + "/client/delete/" + id,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                getClients();
                setdeleteModal(!deleteModal)
                setsuccessModal(true)
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })

    }
    const openItemPopUp = (id, firstname, lastname) => {

        setClientName(firstname + " " + lastname)
        setItemId(id);
        setdeleteModal(true)

    }

    const getTrainer = () => {
        fetch(API_URL + "/trainers?user_id=" + userId,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.log("result", resp);
                    settrainers(resp.trainers);



                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })
    }

    const openStatusPopUp = (id, status) => {
        setItemId(id);
        setStatus(status)
        setStatusModal(true)

    }
    const updateSelectTrainer = () => {

        localStorage.setItem('selectedTrainer', trainerSelected.current.value);

        getClients()
    }



    const updateClient = () => {

        let id = itemId;
        let url = API_URL + "/client/activate/" + id;

        if (status == 1) {
            url = API_URL + "/client/deactivate/" + id;
        }
        fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                getClients();
                setStatusModal(!statusModal)
                setstatssuccessModal(true)
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })
    }

    const getClients = () => {

        let selectedTrainer = localStorage.getItem('selectedTrainer');
        if (selectedTrainer == "all") {
            selectedTrainer = _userId;
            _trainer = false;
        }
        else {
            _trainer = true;
        }

        fetch(API_URL + "/clients?user_id=" + selectedTrainer + "&trainer=" + _trainer + "&user_type=3",

            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }

        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    let _temp = [];
                    resp.clients.map((v, i) => {
                        _temp.push({
                            firstname: v.firstname,
                            lastname: v.lastname,
                            email: v.email,
                            status: v.status == 1 ? "Active" : "Inactive",
                            telephone: v.telephone,
                            actions: <p> <Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title="Edit" placement="top"><a href={"/edit/go/client/" + v.id} className="downloadimg" ><img src={edit} /></a></Tooltip> <Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title={(v.status == 1 ? "Deactivate" : "Activate")} placement="top"><a onClick={() => openStatusPopUp(v.id, v.status)} className="downloadimg"><img src={(v.status == 1 ? Cross : checks)} /></a></Tooltip> <Tooltip classes={{
                                tooltip: classes.customTooltip,

                            }} title="Delete" placement="top"><a onClick={() => openItemPopUp(v.id, v.firstname, v.lastname)} className="downloadimg"><img src={Delete} /></a></Tooltip></p>
                        })
                    })
                    setData(_temp);


                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }


        })


    }

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }



    const columns = [
        {
            title: "First Name", field: "firstname"
        },
        {
            title: "Last Name", field: "lastname"
        },
        {
            title: "Email", field: "email"
        },
        {
            title: "Status", field: "status"
        },

        {
            title: <span className="text-right">Actions</span>, field: "actions", align: "right"
        }
    ]



    return (
        <div className="">
            <Header />
            <div className='container'>
                <div className="wrp-dashbord">
                    <div className="sidebar-section">

                    </div>
                    <div className="right-section2 paddingmobile">

                    <div className="gobpdfsessionheading">
                        <div className="back-icon-wrp">
                           
                            <Link to="#" onClick={ToggleSidebar} className="backbtn-icon">
                                <img src={backIcon} alt="backicon" />
                                <span>Back</span>
                            </Link>
                        </div>
                        <div className='gopdfsettion-head'> <h3 className="heading-mobile">Clients</h3></div>

                        </div>
                        {/* <div className="choose-trainer">
                        <label>Trainer</label>
                        <select ref={trainerSelected} onChange={updateSelectTrainer} className="choose-trainerselectopt">

                            <option selected={"all" == selectedTrainer ? true : false} value={"all"}>All trainers</option>

                            {
                                trainers.map((items, i) => {
                                    return (
                                        <option selected={items.id == selectedTrainer ? true : false} value={items.id}>
                                            {items.firstname} {items.lastname}
                                        </option>
                                    )
                                })
                            }

                        </select>
                    </div> */}
                        <div className="wrp-bankform">
                            <div style={{ maxWidth: '100%' }}>
                              
                                  
                                <MaterialTable
                                    options={{
                                        search: true,
                                        showTitle: true,
                                       
                                        pageSize: data.length <= 5 ? 5: data.length > 5 && data.length <= 10 ? 10: data.length > 10 && data.length <= 20? 20 : data.length > 20 && data.length <=50?50 : data.length > 50 && data.length <= 100?100: 150,

                                        pageSizeOptions: [5, 10, 20,50,100]
                                    }}
                                    columns={columns}
                                    data={data}
                                    title="Clients"
                                />
                           
                            </div>
                        </div>

                        <Modal isOpen={statusModal} toggle={statusToggleModal} className="connect-box" centered={true}>
                            <ModalHeader toggle={statusToggleModal}><span className="ml-1 roititle font-weight-bold">{(status == 1 ? "Deactivate" : "Activate")}</span></ModalHeader>
                            <ModalBody>
                                <div className="modal-p">
                                    {/* <div className="right-circle cancel-circle"><img src={(status == 1 ? Cross : checks)} /></div> */}
                                    <h4>Are you sure?</h4>
                                    <p>Do you really wish to {(status == 1 ? "deactivate" : "activate")} this client?</p>
                                    <div className="wrp-delete-btn">
                                        <div className="cancel-btn1" ><a onClick={statusToggleModal}>Cancel</a></div>
                                        <div className="delete-btn1"><a onClick={updateClient}>{(status == 1 ? "Deactivate" : "Activate")}</a></div>
                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>

                        <Modal isOpen={statssuccessModal} toggle={statussuccessToggleModal} className="connect-box" centered={true}>
                            <ModalHeader toggle={statussuccessToggleModal}><span className="ml-1 roititle font-weight-bold">Successfull</span></ModalHeader>
                            <ModalBody>
                                <div className="modal-p">
                                    <p>Client status updated successfully.</p>
                                </div>
                            </ModalBody>

                        </Modal>

                        <Modal isOpen={deleteModal} toggle={deleteToggleModal} className="connect-box" centered={true}>
                            <ModalHeader toggle={deleteToggleModal}><span className="ml-1 roititle font-weight-bold">Delete</span></ModalHeader>
                            <ModalBody>
                                <div className="modal-p">
                                    <div className="right-circle cancel-circle"><img src={closeicon} /></div>
                                    <h4>Are you sure?</h4>
                                    <p>Do you really wish to delete <b>“{clientName}”</b> client?</p>
                                    <div className="wrp-delete-btn">
                                        <div className="cancel-btn1" ><a onClick={deleteToggleModal}>Cancel</a></div>
                                        <div className="delete-btn1"><a onClick={deleteClient}>Delete</a></div>
                                    </div>
                                </div>
                            </ModalBody>

                        </Modal>

                        <Modal isOpen={successModal} toggle={successToggleModal} className="connect-box" centered={true}>
                            <ModalHeader toggle={successToggleModal}><span className="ml-1 roititle font-weight-bold">Successfull</span></ModalHeader>
                            <ModalBody>
                                <div className="modal-p">
                                    <p>Client deleted successfully.</p>
                                </div>
                            </ModalBody>

                        </Modal>
                    </div>
                </div>
            </div>


            <div className="container-fluid mt-3">

                <div className={`sidebar2 ${isOpen == true ? 'active' : ''}`}>
                    <div className="sd-header">
                        <button className='closebutton' onClick={ToggleSidebar}><img src={crosss} className="img-close" /></button>
                    </div>

                    {
                        dataid == 7 ?

                            <>

                                <div className='profilelist p-5'>
                                    <div className='profilelist p-5'>

                                        <Link to='/go/add/client' onClick={ToggleSidebar}>New Client</Link>
                                        <Link to='/go/list/client' onClick={ToggleSidebar}>Edit Client</Link>
                                        <Link to='/go/edit/profile' onClick={ToggleSidebar}>Edit Profile</Link>
                                    </div>
                                    <div className="BackGouser2">
                                        <Link to="/go/dashboard" onClick={ToggleSidebar}><KeyboardBackspaceIcon />Go to Dashboard</Link>
                                    </div>
                                </div>

                            </>
                            :
                            ""

                    }

                </div>
                <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
            </div>

        </div>
    )
}

export default EditGoclient;